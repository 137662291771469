@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    html {
      scroll-behavior: smooth;
      scroll-padding-top: 100px;
    }
  }

::-webkit-scrollbar {
  display: none;
}
